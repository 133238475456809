<template>
    <b-overlay :show="dataLoaded">
        <b-card title="Obročno plačilo">
            <h4>Minimalno določena cena za plačilo na obroke</h4>
            <b-row class="mt-2">
                <b-col cols="12" sm="6" md="4">
                    <validation-observer ref="validate">
                        <b-form>
                            <validation-provider #default="{ errors }" name="cena" rules="required|min_value:0">
                                <b-form-group label="Minimalna cena obroka">
                                    <b-input-group append="€">
                                        <b-form-input :disabled="!$hasPermissions($permissions.EditInstalmentPayment)" step="0.01" type="number" :value="price" v-on:input="changePrice" placeholder="Cena"/>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider #default="{ errors }" name="min_instalment" rules="required|min_value:0|max_value:@max_instalment">
                                <b-form-group label="Minimalno št obrokov">
                                    <b-form-input :disabled="!$hasPermissions($permissions.EditInstalmentPayment)" step="0.01" type="number" :value="min_installment" v-on:input="changeMinInstallment" placeholder="Min st obrokov"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider #default="{ errors }" name="max_instalment" rules="required|min_value:@min_instalment">
                                <b-form-group label="Maksimalno št obrokov">
                                    <b-form-input :disabled="!$hasPermissions($permissions.EditInstalmentPayment)" step="0.01" type="number" :value="max_installment" v-on:input="changeMaxInstallment" placeholder="Max st obrokov"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>


                            <b-row>
                                <b-col cols="12">
                                    <b-row>
                                        <b-col cols="4" class="text-center">Št. Obrokov</b-col>
                                        <b-col cols="8">BBIS ID</b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <b-row class="px-1" v-if="max_installment && min_installment && !(Number(min_installment) >= Number(max_installment)) && !(Number(max_installment) <= Number(min_installment))">
                                <b-col cols="12" class="mt-1" v-for="item,index in installments" :key="'instalment_'+ index" style="border: 1px solid black;">
                                    <b-row >
                                        <b-col cols="4" class="justify-content-center align-items-center d-flex">
                                            <div>
                                                {{ Number(min_installment) + Number(index) }}
                                            </div>
                                        </b-col>
                                        <b-col cols="8" style="padding-top: 10px; padding-bottom: 10px;">
                                            <validation-provider #default="{ errors }" :name="'BBIS_ID ' + (Number(min_installment) + Number(index))" rules="required">
                                                <b-form-input :disabled="!$hasPermissions($permissions.EditInstalmentPayment)" type="number" v-model="installments[index].value" placeholder="BBIS ID"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                
                                </b-col>
                            </b-row>
                            <b-button class="mt-2" variant="primary" @click="setPrice" v-if="$hasPermissions($permissions.EditInstalmentPayment)">Posodobi</b-button>
                        </b-form>
                    </validation-observer>
                </b-col>

            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue } from '@validations'
    import {BButton, BCard, BCol, BOverlay, BRow, BFormInput, BInputGroup, BForm, BFormGroup} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BFormInput,
            BInputGroup,
            BForm,
            BFormGroup
        },
        data() {
            return {
                installments: [],
                dataLoaded: false,
                price : 0,
                min_installment: 0,
                max_installment: 0,
                object: {
                    id: '',
                    price: 0,
                    min_installment: 0,
                    max_installment: 0,
                    config: {}
                },
                required,
                minValue
            }
        },
        methods:{
            changeMaxInstallment(val) {
                this.max_installment = val
                this.object.max_installment = Number(val)

                const minInstallments = Number(this.min_installment)
                const maxInstallments = Number(this.max_installment)


                if (minInstallments > 0 && minInstallments < maxInstallments) {
                    this.installments = []
                    for (let i = 0; i <= (maxInstallments - minInstallments); i++) {
                        this.installments.push({
                            key: minInstallments + i,
                            value: null
                        })
                    }

                } else {
                    this.installments = []
                }
            },
            changeMinInstallment(val) {
                this.min_installment = val
                this.object.min_installment = Number(val)

                const minInstallments = Number(this.min_installment)
                const maxInstallments = Number(this.max_installment)

                if (minInstallments > 0 && minInstallments < maxInstallments) {
                    this.installments = []
                    for (let i = 0; i <= (maxInstallments - minInstallments); i++) {
                        this.installments.push({
                            key: minInstallments + i,
                            value: null
                        })
                    }
                } else {
                    this.installments = []
                }
            },
            changePrice(val) {
                this.price = val
                this.object.price = Number(Number(val * 100).toFixed(0))
            },
            async loadData() {

                this.dataLoaded = true
                try {
                    const res = await this.$http.get('/api/management/v1/shop/installment_payment/')
                    this.object = res.data
                    this.price =  res.data.price / 100
                    this.min_installment =  res.data.min_installment
                    this.max_installment =  res.data.max_installment
                    this.installments = res.data.config || []

                    if (this.installments && this.installments.length === 0) {
                        if (this.min_installment > 0 && this.max_installment > 0 && this.min_installment < this.max_installment) {
                            for (let i = 0; i < (this.max_installment - this.min_installment + 1); i++) {
                                this.installments[i] = {
                                    key: Number(this.min_installment) + i,
                                    value: Number(this.min_installment) + i
                                }
                            }
                        }
                    }

                } catch (err) {
                    this.$printError(err.response.statusText)
                } finally {
                    this.dataLoaded = false
                }
            },
            async setPrice() {
                const thisIns = this
                this.showLoader = true
                this.object.config = []

                for (const item of this.installments) {
                    this.object.config.push({
                        key: Number(item.key),
                        value: Number(item.value)
                    }
                    )
                }

                const rez = await this.$refs.validate.validate()
                
                if (!rez) {
                    return
                }
            
                const loadPromise = this.$http.put('/api/management/v1/shop/installment_payment/', this.object)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Cena je posodobljena')
                }).catch(function(err) {
                    thisIns.$printError(err.response.statusText)
                }).finally(() => {
                    this.showLoader = false
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>

.cut-text{
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

</style>