var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.dataLoaded}},[_c('b-card',{attrs:{"title":"Obročno plačilo"}},[_c('h4',[_vm._v("Minimalno določena cena za plačilo na obroke")]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('validation-observer',{ref:"validate"},[_c('b-form',[_c('validation-provider',{attrs:{"name":"cena","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Minimalna cena obroka"}},[_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"disabled":!_vm.$hasPermissions(_vm.$permissions.EditInstalmentPayment),"step":"0.01","type":"number","value":_vm.price,"placeholder":"Cena"},on:{"input":_vm.changePrice}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"min_instalment","rules":"required|min_value:0|max_value:@max_instalment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Minimalno št obrokov"}},[_c('b-form-input',{attrs:{"disabled":!_vm.$hasPermissions(_vm.$permissions.EditInstalmentPayment),"step":"0.01","type":"number","value":_vm.min_installment,"placeholder":"Min st obrokov"},on:{"input":_vm.changeMinInstallment}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"max_instalment","rules":"required|min_value:@min_instalment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Maksimalno št obrokov"}},[_c('b-form-input',{attrs:{"disabled":!_vm.$hasPermissions(_vm.$permissions.EditInstalmentPayment),"step":"0.01","type":"number","value":_vm.max_installment,"placeholder":"Max st obrokov"},on:{"input":_vm.changeMaxInstallment}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_vm._v("Št. Obrokov")]),_c('b-col',{attrs:{"cols":"8"}},[_vm._v("BBIS ID")])],1)],1)],1),(_vm.max_installment && _vm.min_installment && !(Number(_vm.min_installment) >= Number(_vm.max_installment)) && !(Number(_vm.max_installment) <= Number(_vm.min_installment)))?_c('b-row',{staticClass:"px-1"},_vm._l((_vm.installments),function(item,index){return _c('b-col',{key:'instalment_'+ index,staticClass:"mt-1",staticStyle:{"border":"1px solid black"},attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"justify-content-center align-items-center d-flex",attrs:{"cols":"4"}},[_c('div',[_vm._v(" "+_vm._s(Number(_vm.min_installment) + Number(index))+" ")])]),_c('b-col',{staticStyle:{"padding-top":"10px","padding-bottom":"10px"},attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":'BBIS_ID ' + (Number(_vm.min_installment) + Number(index)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.$hasPermissions(_vm.$permissions.EditInstalmentPayment),"type":"number","placeholder":"BBIS ID"},model:{value:(_vm.installments[index].value),callback:function ($$v) {_vm.$set(_vm.installments[index], "value", $$v)},expression:"installments[index].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),1):_vm._e(),(_vm.$hasPermissions(_vm.$permissions.EditInstalmentPayment))?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":_vm.setPrice}},[_vm._v("Posodobi")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }